import React from "react";
import "./App.css";
import $ from "jquery";
import axios from "axios";
import ReactDOM from 'react-dom'
import CSVReader from 'react-csv-reader'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const txtToJSON = require("txt-file-to-json");
toast.configure();

  
class App extends React.Component {
  constructor(props) {
	super(props);
    this.state = {
      isActive: false,
      uploadInput: true,
      resetBtn:false,
      imgUpload: false,
	  jsonExp:false,
	  loader:false,
	  skinclr: "",
	  rgb_v:"",
	  rgb_values:"",
      orgnalImg: "",
	  modifyst:false,
	  allVal:{},
      orgrgb: "",
	  modeclr:"",
	  modr:"",
	  maskImg: "",
	  modg:"",
	  modb:"",
      selectedFile: "",
      errors: {},
	  change_r:{},
  	  change_g:{},
	  change_b:{},
	  dchange_r:{},
	  dchange_g:{},
	  dchange_b:{},
	  contrast:{},
	  nextimg:"",
	  manuallyChanged:false,
	  indexSelected:"",
	  subIndex:""
	 

    };
		this.onhandleSubmit = this.onhandleSubmit.bind(this);
		this.manualSlct = this.manualSlct.bind(this);
		this.uplodImg = this.uplodImg.bind(this);
		this.resetProcess = this.resetProcess.bind(this);
		this.exportJson = this.exportJson.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
	}


  
  //submit
   onhandleSubmit =(e) =>	 {
	    this.setState({
		   loader:true
	    })
	    console.log(this.state.rgb_v)
	    console.log('this.state.rgb_v')
	   let applyid = e.currentTarget.getAttribute("id");
	   $('#'+applyid).attr('disabled','disabled').html(
		 '<i className="fa fa-spinner fa-spin fa-1x fa-fw"></i><span className="sr-only">Loading...</span>'
	   );
	  
	const inpr = Number(e.currentTarget.getAttribute("redt"));
	const inpg = Number(e.currentTarget.getAttribute("gret"));
	const inpb = Number(e.currentTarget.getAttribute("blut"));
	const applyr = Number(e.currentTarget.getAttribute("red"));
	const applyg = Number(e.currentTarget.getAttribute("green"));
	const applyb = Number(e.currentTarget.getAttribute("blue"));
	let rr = inpr + applyr - this.state.rgb_v[0][2];		
	let gg = inpg + applyg - this.state.rgb_v[0][1];		
	let bb = inpb + applyb - this.state.rgb_v[0][0];		
	const rgb_vv = [...this.state.rgb_v];
    
    const r = Number(e.currentTarget.getAttribute("red"))+inpr
    const g = Number(e.currentTarget.getAttribute("green"))+inpg
    const b = Number(e.currentTarget.getAttribute("blue"))+inpb
	const contr =Number(e.currentTarget.getAttribute("cont"))
	
    var rgb_values= JSON.parse(localStorage.getItem('rgb_values'));
    var rgb_initial_values= JSON.parse(localStorage.getItem('rgb_initial_values'));
    //var value_g=localStorage.getItem('value_g');
    //var value_b=localStorage.getItem('value_b');
	
    const clrback = e.currentTarget.getAttribute("clrback")
	//rgb_values
	//alert(rgb_values[clrback][2])
	// alert()
	//var modified_r=parseInt(rgb_values[clrback][2])-parseInt(e.currentTarget.getAttribute("red"));
	// var modified_g=parseInt(rgb_values[clrback][1])-parseInt(e.currentTarget.getAttribute("green"));
	// var modified_b=parseInt(rgb_values[clrback][0])-parseInt(e.currentTarget.getAttribute("blue"));
	
	var modified_r=parseInt(e.currentTarget.getAttribute("red"))+parseInt(rgb_values[clrback][2]);
	var modified_g=parseInt(e.currentTarget.getAttribute("green"))+parseInt(rgb_values[clrback][1]);
	var modified_b=parseInt(e.currentTarget.getAttribute("blue"))+parseInt(rgb_values[clrback][0]);
	

	const modified = "rgb(" + modified_r+ ","+modified_g+","+modified_b+")";
	$('#datargb'+clrback).css("background",modified);
	$('#updval'+clrback).text(modified);
   	// alert(r)
   	// alert(b)
   	// alert(g)
    // alert(this.state.rgb_values[clrback][2])
    this.setState({
    	applied_r:modified_r,
    	applied_g:modified_g,
    	applied_b:modified_b
    })
     console.log(this.state.rgb_v)
	    console.log('this.state.rgb_v')

    rgb_values[clrback][2]=modified_r;
    rgb_values[clrback][1]=modified_g;
    rgb_values[clrback][0]=modified_b;
    localStorage.setItem('rgb_values',JSON.stringify(rgb_values))
    this.state.rgb_v[clrback][0]=e.currentTarget.getAttribute("blue");
    this.state.rgb_v[clrback][1]=e.currentTarget.getAttribute("green");
    this.state.rgb_v[clrback][2]=e.currentTarget.getAttribute("red");

     	console.log(this.state.rgb_v)
	    console.log('this.state.rgb_v')
	   let formData = new FormData();
			formData.append("ImgPath", this.state.orgnalImg);
			formData.append("Red", e.currentTarget.getAttribute("red"));
			formData.append("Green", e.currentTarget.getAttribute("green"));
			formData.append("Blue", e.currentTarget.getAttribute("blue"));
			formData.append("MaskPath",this.state.nextimg);
			formData.append("contrast",contr);
			axios
			  .post("https://beauty.itechnolabs.tech/getfinal", formData)
			  .then((res) => {
					if(res){
					
						this.setState({
							  outputImg:res.data.output_img_final+'?'+Date.now(),
							  modeclr:modified,
							  modifyst:true,
							  modr:r,
							  modg:g,
							  modb:b,
							  loader:false
							
						});
					}
					$('#'+applyid).prop('disabled',false);
					$('#'+applyid).html('Apply');
			  })
			  .catch((err) => {
				console.log(err);
			  });
		}
	
//import validation		
  handleValid(a){
	    let errors = {};
		let importIsValid = true;
    console.log(a)
	if (a.length != 12) {
	  importIsValid = false;
	  errors["imperr"] = "Import Data is not valid. Please check sample format.";
	}
	if(a.length == 12){
		
		{a.map((name, index)=>{
		
			if(isNaN(name[1]) || isNaN(name[0]) || isNaN(name[2])){
			
					importIsValid = false;
					  errors["imperr"] = "RGB value should be integer.";
				}
			
			})
		}
	}
	
	this.setState({ errors: errors });
    return importIsValid;
	
  }
  
  
  //import json
	handleFileChange = e => {
		//file read of json
		const fileReader = new FileReader();
		fileReader.readAsText(e.target.files[0], "UTF-8");
		fileReader.onload = e => {
		const dataInJSON = txtToJSON({ data: e.target.result });

		console.log(dataInJSON)
		var a =[];
		var allValues = [];
		dataInJSON.map((val,index) =>(
			allValues.push("rgb(" + val.R+ ","+ val.G+","+ val.B+")"),
			a[index] = {0:Number(val.B),1:Number(val.G),2:Number(val.R)}

		));
		
			if (this.handleValid(Object.values(a))) {
			this.setState({
					// allVal:allValues,
					rgb_v: Object.values(a),
					rgb_values:Object.values(a)
				});
			console.log(a)
			console.log(Object.values(a))
			document.getElementById("closeModal").click()	
			};
		}
		
	};
  
	
  
  
  //Img upload action	
  uplodImg() {
    if(this.state.selectedFile){
		 $("#uploadbtn")
          .attr("disabled", "disabled")
          .html(
            '<i className="fa fa-spinner fa-spin fa-1x fa-fw"></i><span className="sr-only">Loading...</span>'
          );
			var skinTon = "";
			let formData = new FormData();
			formData.append("file", this.state.selectedFile);
			axios
			  .post("https://beauty.itechnolabs.tech/passdata", formData, {
				headers: {
				  "Content-type": "multipart/form-data",
				},
			  })
			  .then((res) => {
				if (res && !res.data.Error) {
				  skinTon =
					"rgb(" + res.data.avrage_colrtone_input_BGR.reverse().join() + ")";
				  var orgnlr = res.data.avrage_colrtone_input_BGR[2]; 
				  var img = new Image();
				  var response_data=res.data.all_chips;
                  var allValues = [];
				  const updated_b = []
				  const updated_g = []
				  const updated_r = []
				  const dupdated_r = []
				  const dupdated_g = []
				  const dupdated_b = []
				  var primaryColor=[];

				  var allData=[
								[-6,-4,-2],
								[-8, -6, -2],
								[-10, -6, -3],
								[-11, -7, -3],
								[-12, -7, -3],
								[-12, -6, -2],
								[-12, -6, -2],
								[-11, -5, -1],
								[-10, -4, 1],
								[9, -3, 2],
								[8, -3, -4],
								[7, 2, -5]
							]
				  {res.data.all_chips && res.data.all_chips.map((val,index) => (  
							allValues.push("rgb(" + val[2]+ ","+ val[1]+","+ val[0]+")"),
							updated_b[index] = val[0],
							updated_g[index] = val[1],
							updated_r[index] = val[2],
							dupdated_r[index] = 0,
							dupdated_g[index] = 0,
							dupdated_b[index] = 0
				  ))}  
				  this.setState({
   				    change_r: updated_r,
					contrast: dupdated_r,
					change_g: updated_g,
					change_b: updated_b,
					dchange_r:dupdated_r,
					dchange_g:dupdated_g,
					dchange_b:dupdated_b,
					orgnalImg: res.data.input_img,
					nextimg:res.data.output_img_mask,
					skinclr: skinTon,
					orgrgb: res.data.avrage_colrtone_input_BGR,
					uploadInput: false,
					maskImg: "https://beauty.itechnolabs.tech/" + res.data.output_img_mask,
					allVal:allValues,
					rgb_v:allData,
					rgb_values:allData,
					isActive: !this.state.isActive,
				  });
					}else{
					this.showError('Please Change your uploaded file...!!');
					$("#uploadbtn").prop("disabled", false);
					this.setState({
						imgUpload:false,
					});
				}

				localStorage.setItem('rgb_values',JSON.stringify(response_data));
				localStorage.setItem('rgb_initial_values',JSON.stringify(response_data));
				// localStorage.setItem('value_g',response_data[0][1]);
				// localStorage.setItem('value_b',response_data[0][0]);
				 $("#uploadbtn").html('Upload');
			  })
			  .catch((err) => {
				console.log(err);
				 $("#uploadbtn").html('Upload');
			  });
			
			$("#uploadbtn").attr("disabled", "disabled");
	}else{
		this.showError('Upload File should not be Empty..!!');
	}
  }
 
 
  //Json Export Fee
  exportJson(){
	const JsonExport=[]
	// /console.log(this.state.rgb_v);
	// return;
	// this.state.change_r.map((val,index) => (  
		// JsonExport[index+1] = [val],
		// JsonExport[index+1].push(this.state.change_g[index]),
		// JsonExport[index+1].push(this.state.change_b[index])

		//JsonExport[index+1]= this.state.rgb_v[index]
	// ));

	for (var i = 0; i < this.state.rgb_v.length; i++) {
		var arr=[]
		arr[0]=this.state.rgb_v[i][2]
		arr[1]=this.state.rgb_v[i][1]
		arr[2]=this.state.rgb_v[i][0]
		console.log(arr)
		JsonExport[i+1]=arr;
	}
	// console.log(JsonExport)
	// return
	JsonExport[0] = ['R  G  B']
	const filename = 'RGB_Values.json';
	const jsonStr = JSON.stringify(JsonExport);

	let element = document.createElement('a');
	element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr));
	element.setAttribute('download', filename);
	element.style.display = 'none';
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
	  
  }
 
  //error function
  showError(msg){
		toast.error(msg, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
  }
 
  // validation 	
  handleValidation(nval) {
    let errors = {};
    let formIsValid = true;
    
	if (nval==null || nval=="") {
	  formIsValid = false;
	  errors["nval"] = "Value Cannot be empty or string";
	}
	if (nval<-256 || nval>0) {
	  formIsValid = false;
	  errors["nval"] = "Value should be less than -256 or greater than 0";
	}
	this.setState({ errors: errors });
    return formIsValid;
  }
  
  
  //adjust rgb marked custom/manually	
  manualSlct(index, newValue, inp, privalue, value, priMainIndex,priSubIndex,valueIndex){
	 
	 console.log('hiiii')
	 console.log(newValue.target.value)
	 console.log(this.state.rgb_values)
	 console.log(this.state.rgb_v)
	 // console.log(priSubIndex)
	 // console.log(valueIndex)
	 // console.log()
	this.setState({manuallyChanged:true,indexSelected:valueIndex,subIndex:priSubIndex})
	this.state.rgb_v[valueIndex][priSubIndex]= newValue.target.value;
	console.log(this.state.rgb_v[valueIndex][priSubIndex])
	if (this.handleValidation(newValue)) {
		  const rgb_vv = [...this.state.rgb_v];
		  const updated_r = [...this.state.change_r]; 
		  const updated_g = [...this.state.change_g];
		  const updated_b = [...this.state.change_b];
		  
		  const dupdated_r = [...this.state.dchange_r];
		  const dupdated_g = [...this.state.dchange_g];
		  const dupdated_b = [...this.state.dchange_b];
		  const dcontrast = [...this.state.contrast];	
		  if(inp == 'red'){ 
			  				dupdated_r[index] = Number(newValue.target.value);
							}
							
		  if(inp =='blue'){
						  	dupdated_b[index] = Number(newValue.target.value);

						  }
		  if(inp =='contrast'){
			  	dcontrast[index] = Number(newValue.target.value);
		  }
		  
		  if(inp == 'green'){
							dupdated_g[index] = Number(newValue.target.value);
						}
			this.setState({
				dchange_r:dupdated_r,
				dchange_g:dupdated_g,
				dchange_b:dupdated_b,
				contrast:dcontrast
			})	
		}
  }
 

  //reset
  resetProcess(){
	 window.location.reload();
  }	
  
  
  render() {
	  const modeStyle={
		background:this.state.modeclr
	}
	
	 const dotStyles = {
      background: this.state.skinclr,
    };
	 const dotStyless = {
      background: this.state.skinclr,
    };

    const preview = (event) => {
      let imgPrev = URL.createObjectURL(event.target.files[0]);
	  $('#prvw').remove();
	  if(event.target.files[0].type != 'image/jpeg' && event.target.files[0].type != 'image/jpg' && event.target.files[0].type != 'image/png'){
		this.showError('File format is not acceptable....!!');
	  }else{
    	  $('#frame').removeClass('d-none');
		  $("#frame").attr("src", imgPrev);
      this.setState({
        imgUpload: true,
        selectedFile: event.target.files[0],
      });	  
	  }
   };

   const handleForce = (data, fileInfo) => {
	   	var rgb_values_imported=[];
	   	console.log(data);
 	   	for (var i = 0; i < data.length; i++) {
	   		//Things[i]

	   		var valueToPush = new Array();
			valueToPush[0] = data[i].b;
			valueToPush[1] = data[i].g;
			valueToPush[2] = data[i].r;
			rgb_values_imported.push(valueToPush);


	   		console.log(data[i].r)
	   		// rgb_values\_imported[i][]=data[i].b
	   		// fruits.push("Kiwi");
	   	}

	   	console.log('rgb_values_imported')
	   	console.log(rgb_values_imported)
	   	this.setState({rgb_v:rgb_values_imported,rgb_values:rgb_values_imported})
	   	// localStorage
	   	document.getElementById("closeModalCsv").click()
   }

	const papaparseOptions = {
	  header: true,
	  dynamicTyping: true,
	  skipEmptyLines: true,
	  transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
	};

    return (
	
      <div className="App">
			<div className="modal" id="importModal">
			  <div className="modal-dialog">
				<div className="modal-content">
  		           <div className="modal-header">
					 <h5>Upload Json file</h5>
					<button type="button" id="closeModal"   className="close" data-dismiss="modal">&times;</button>
				  </div>
				  <div className="modal-body">
						<div className="row">
							<div className="col-md-4">
								<input type="file" onChange={this.handleFileChange} />
							</div>
							<div className="col-md-4"></div>

							<div className="cold-md-4 text-right">
								<a href='/Sample.json' download="Sample">
									View Sample File
								</a>
							</div>
						</div>
						<div className="row">
							<h5>{this.state.errors["imperr"]} </h5>
						</div>
				  </div>
				</div>
			  </div>
			</div>
			<div className="modal" id="importCsvModal">
			  <div className="modal-dialog">
				<div className="modal-content">
  		           <div className="modal-header">
					 <h5>Upload CSV file</h5>
					<button type="button" id="closeModalCsv"   className="close" data-dismiss="modal">&times;</button>
					
				  </div>
				  <div className="modal-body">
						<div className="row">
							<div className="col-md-4">
								<CSVReader
							      cssClass="react-csv-input"
							      
							      onFileLoaded={handleForce}
							      parserOptions={papaparseOptions}
							    />

							</div>
							<div className="col-md-4"></div>

							<div className="cold-md-4 text-right">
								<a href='/Sample.csv' download="Sample">
									View Sample File
								</a>
							</div>
						</div>
						<div className="row">
							<h5>{this.state.errors["imperr"]} </h5>
						</div>
				  </div>
				</div>
			  </div>
			</div>
	      
	  <div className="container-fluid p-5">
	       {this.state.uploadInput && ( 
		   <>
		   <div className="heading_cu7">
					  <h5 className="text-left mb-4"> Image File </h5>
					</div>
				  <div className="row">
					
					<div className="col-md-6 text-left">
					<div className="custom_inpuy6">
					  <input
						type="file"
						className="mt-2"
						id="imgInp"
						width="100"
						onChange={preview}
					  />
					  </div>
					</div>
					<div className="col-md-2 text-left">
					<div className="custom_button6">
					  <button
						className="btn btn-sm btn-primary py-1 px-4"
						onClick={this.uplodImg}
						type="submit"
						id="uploadbtn"
					  >
						Upload
					  </button>
					  </div>
					</div>
					<div className="col-md-2 text-left">
					<div className="custom_button7">
					  <button className="btn btn-sm btn-secondary py-1 px-4" id="prvw"> Preview </button>
					  
					  <img
					  src="#"
						className="img ml-4 d-none"
						id="frame"
						width="160"
						height="120"
						alt="preview"
					  />
					</div>
					</div>
				  </div>
			</>
				  )} 
          {this.state.imgUpload && (
            <div
              className="row"
              id="wheels"
              style={this.state.isActive ? {} : { display: "none" }}
            >
              <div className="col-md-12 text-left">
			  <div className="heading_cu7">
					  <h2 className="text-left mb-4"> Unity Color Preview Utility </h2>
					</div>
                <div className="row mt-4">
                <div className="col-md-8">
				 <div className="row">
                  <div className="col-md-6 text-left custom_img">
                    <h6> Original Image </h6>
					<div className="modify_img_outer">
                    <img
                      src={'https://beauty.itechnolabs.tech/'+this.state.orgnalImg}
                      className="rounded"
                      alt="Original"
                    />
					</div>
                    <p className="avg_txt m-0 p-0 my-2">
                      Average Skin Tone of Original Image:
                    </p>
                    <span className="orgnldot" style={dotStyles}></span>
                    <p id="orgrgb">
                      {this.state.orgrgb &&
                        this.state.orgrgb.map((rgb, index) => (
                          <span key={index}>
                            {index === 0 ? "R-" : index === 1 ? "G-" : "B-"}
                            {(Math.round(rgb * 100) / 100).toFixed(2)}
                            {index < 2 ? "|" : ""}
                          </span>
                        ))}
                    </p>

                    
                  </div>
                 
                  <div className="col-md-6 custom_img">
				  {this.state.modifyst && (
					  <>
					<h6> Modified Image </h6>
					
                    {(this.state.loader)?
					<div className="loader_text">
					<p className="text-center">
						<i class="fa fa-spinner fa-spin fa-1x fa-fw"></i><span class="">Loading...</span>
					</p>
					</div>
					:
					<div className="modify_img_outer">
					<img
                      src={'https://beauty.itechnolabs.tech/'+this.state.outputImg}
                      className="rounded"
                      alt="Modified Image"
                    />
										</div>

					}
					
					
					  <p className="avg_txt m-0 p-0 my-2">
                      Modified Skin Tone:
                    </p>
                    <span className="orgnldot" style={modeStyle}></span>
					<p id="orgrgb"> 
                          <span> R-{this.state.applied_r} |
                          </span>
                          <span> R-{this.state.applied_g} |
                          </span>
                          <span> R-{this.state.applied_b}
                          </span>
                    </p>
					</>
					)}
                   
                  </div>
				  </div>
				  </div>
				  <div className="col-md-4">
					  <div className="row">
                  <div className="col-md-12">
				 
						
					<div className="table_row">
					   <div className="table_custom_outer">
						 <table className="table table-bordered">
						    <thead>
							  <tr>
								<th colSpan="2">&nbsp;</th>
								<th className="offset" colSpan="3">Offsets from Original RGB</th>
								<th colSpan="2">&nbsp;</th>
							  </tr>
							</thead>
							<thead>
							  <tr>
								<th>#</th>
								<th>Color Chip</th>
								<th className="r">R</th>
								<th className="g">G</th>
								<th className="b">B</th>
								<th>Action</th>
							  </tr>
							</thead>
							<tbody>
						  {this.state.allVal && this.state.allVal.length > 0 && this.state.allVal.map((name, index)=>
								<tr key={index}>
						  			<td >
						  				{index+1}
						  			</td>
									<td>
										<span className="orgnldots" id={'datargb'+index} style={{ background: name }} ></span>
										<span id={'updval'+index} >{name} </span>
									</td>
									<td>
										<div className="row">
										
											<div className="col-md-12">
												<input value={this.state.rgb_v[index][2]} className="form-control p-0 rgbinput"   data-index={index}  maxLength="4"   type="number" id="rcap" kesy={index[2]}   onChange={e => this.manualSlct(index, e, 'red',this.state.rgb_v[0][2],this.state.rgb_v[index][2],0,2,index )} />
											</div>
										</div>

									</td>
									<td>
										<div className="row">
											
											<div className="col-md-12">
												<input value={this.state.rgb_v[index][1]} className="form-control p-0 rgbinput"  data-index={index}  maxLength="4"  type="number" id="gcap" kesy={index[1]}   onChange={e => this.manualSlct(index, e, 'green',this.state.rgb_v[0][1],this.state.rgb_v[index][1],0,1,index)} />
											</div>
										</div>	
									</td>
									<td>
										<div className="row">
											
											<div className="col-md-12">
												<input value={this.state.rgb_v[index][0]} className="form-control p-0 rgbinput" data-index={index} maxLength="4"  type="number" id="bcap" kesy={index[0]} onChange={e => this.manualSlct(index, e, 'blue',this.state.rgb_v[0][0],this.state.rgb_v[index][0],0,0,index)}   />
											</div>
										</div>	
									</td>
								 
									<td>
										<button className="reset_btn" id={'apply-'+index} cont={this.state.contrast[index]} redt={this.state.dchange_r[index]} gret={this.state.dchange_g[index]} blut={this.state.dchange_b[index]} onClick={this.onhandleSubmit}  clrback={index} red={this.state.rgb_values[index][2]} green={this.state.rgb_values[index][1]} blue={this.state.rgb_values[index][0]} >Apply</button>
									</td>
								  </tr>					 
								)}

						
							
							</tbody>
						  </table>
						  <span className="error_result" style={{ color: "red" }}>
                          {this.state.errors["nval"]}
                        </span>
						  </div>
					</div>
					
                    <div className="row mt-2 ml-0">
                      <input id="hslInput" className="d-none" />
                      <input id="hexInput" className="d-none"></input>
                     
                     
					 <div className="btn_outer99"> 
					  <button
                        className="btn  btn-sm btn-primary ml-2 resultsub"
                        id="submitres"
						data-toggle="modal"
						data-target="#importModal"
                      >Import Json </button> 

                      <button
                        className="btn  btn-sm btn-primary ml-2 resultsub"
                        id="submitres"
						data-toggle="modal"
						data-target="#importCsvModal"
                      >Import CSV</button> 


					 <button
                        className="btn  btn-sm btn-primary ml-2 resultsub"
                        onClick={this.exportJson}
                        id="submitres"
                      >					 Export Table Values(JSON)</button> 
					  	 
						 </div>
						 <div className="btn_outer99"> 
						 <button
							className="btn btn-sm btn-secondary ml-2"
							onClick={this.resetProcess}
							type="submit"
							id="reset"
						  >
							Reset Image
						  </button>
						 </div>
						
                    </div>
                  </div>
				  </div>
				  </div>
                  
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default App;
